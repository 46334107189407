.comments {
  width: 100%;
  min-height: 110px;
  border: none;
  background-color: #ffffff;
  color: #3d5170;
  border-bottom: 1px solid #ced3dc;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.comments__header {
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  width: 100%;
}
.comments__list {
  max-height: 340px;
  overflow-y: auto;
  padding-right: 8px;
  box-sizing: border-box;
  scrollbar-color: #11a8b8 #edeff5;
}

.comment {
  margin-bottom: 24px;
}

.comment__by {
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
}

.comment__author {
  font-size: 14px;
  font-weight: 500;
}

.comment__content {
  font-weight: 400;
  font-size: 14px;
  color: #3d5170;
  word-wrap: break-word;
}

.processed-info {
  color: #11a8b8;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
}

.no-comments {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  color: #8493ab;
}

.comment-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
  min-height: 29px;
  border-bottom: 1px solid #ced3dc;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  background-color: #edeff5;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.textarea {
  width: 100%;
  min-height: 29px;
  max-height: 200px;
  resize: none;
  background-color: #edeff5;
  border: none;
  color: #3d5170;
  outline: none;
  margin-bottom: 0;
  font-size: 14px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.comment-button {
  position: absolute;
  right: 10px;
  height: 28px;
  width: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #8493ab;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.comment-button.filled {
  background-color: #11a8b8;
}

.expanded {
  min-height: auto;
}

.not-expanded {
  height: 45px;
}

.expanded .comment-button {
  position: relative;
  right: 0;
  bottom: 0;
  margin-top: 10px;
  align-self: flex-end;
}
