.multi-document-uploader {
  margin-bottom: 10px;
}

.document-uploader-button {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  height: 43px;
  background-color: #f7f8fa;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #3d5170;
  font-family: Roboto;
  line-height: 20.02px;
  letter-spacing: 0.15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  cursor: pointer;
}

.file-list {
  background-color: #ffffff;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0px 0px 10px 10px;
  color: #3d5170;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 10px 15px;
}

.placeholder-text {
  opacity: 30%;
  font-style: italic;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f8fa;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 5px;
}
