.card {
  width: 100%;
  padding: 10px 0px 0px 0px;
  border-radius: 10px;
  border-bottom: 1px solid #8493ab;
  background: #ffffff;
  justify-content: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.card__p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #3d5170;
}

.card__flex {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.input__name {
  width: 50%;
  height: 41px;
  padding: 6px 10px 6px 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ced3dc;
  background-color: #ffffff;
  color: #3d5170;
}

.input__name::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #ced3dc;
}
.input__mail {
  width: 100%;
  height: 41px;
  padding: 6px 10px 6px 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ced3dc;
  background-color: #ffffff;
  color: #3d5170;
}

.input__mail::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: #ced3dc;
}
