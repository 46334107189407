.custom-button {
  height: 48px;
  padding: 12px 16px 11px 16px;
  font-size: 16px;
  border-radius: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.custom-button__left-icon {
  display: grid;
  grid-template-columns: repeat(1, auto) 1fr;
  justify-items: center;
  align-items: center;
}

.custom-button__gray {
  background-color: #3d5170;
  color: #ffffff;
}

.custom-button__light-gray-new {
  background-color: #8493ab;
  color: #ffffff;
}
.custom-button__light-gray {
  background-color: #ced3dc;
  color: #3d5170;
}

.custom-button__green {
  background-color: #11a8b8;
  color: #ffffff;
}

.custom-button__white {
  background-color: #ffffff;
  color: #3d5170;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.custom-button__red {
  background-color: #cb3844;
  color: #ffffff;
}

.custom-button__icon {
  display: flex;
}

.custom-button--disabled {
  /* Styles for the disabled state */
  opacity: 20%;
  cursor: not-allowed;
}

/* Custom button width classes */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-48 {
  width: 48px;
  gap: 0;
}

.w-36 {
  width: 36px;
  gap: 0;
  height: 36px;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}
