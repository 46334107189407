.content-wrapper {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.services__issue-type {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px #ced3dc solid;
  padding: 10px;
  margin: 16px 0;
}

.services__issue-type__title {
  color: #3d5170;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-left: 5px;
}

.servicess__issue-type__title--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.services__issue-type__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.services__issue-type__button {
  color: #8493ab;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  border-radius: 8px;
  border: 2px #8493ab solid;
  padding: 7px;
  width: 49%;
  text-align: center;
  cursor: pointer;
}

.services__issue-type__button--active {
  background-color: #11a8b8;
  color: #fff;
  border-color: #11a8b8;
}

.services__issue-type__button--readonly {
  cursor: default;
  pointer-events: none;
}

.services__issue-type__button--readonly--inactive {
  opacity: 25%;
}
