.customer-monitors {
  width: 100%;
}

.customer-monitors__heading {
  display: flex;
  align-items: center;
}

.customer-monitors__title {
  display: flex;
  align-items: center;
  color: #3d5170;
  font-size: 16px;
  font-weight: 500;
}

.customer-monitors__title > img {
  margin-right: 16px;
}

.tabs {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
}

.buttons {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.image {
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  border-bottom: 1px solid #c4c4c4;
  height: 600px;
  width: 100%;
}
.image-container {
  background-color: #f2f2f2;
  width: 100%;
  margin-top: 0px !important;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.monitor-details {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;
  max-height: 600px;
  word-wrap: break-word;
}

.monitor-details__p-bold {
  font-size: 14px;
  font-weight: 600;
}

.monitor-details__p {
  font-size: 14px;
  font-weight: 400;
}

.info-button {
  color: white;
  background: #3d5170;
  width: 80px;
  border-radius: 8px;
  font-size: 14px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
}
.gray-p {
  color: #8493ab;
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.materials-item {
  display: flex;
  justify-content: space-between;
  color: #3d5170;
  font-weight: 500;
  border-bottom: 0.5px solid #ced3dc;
  padding: 10px 0;
}

.last-service-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 0.5px solid #ced3dc;
  align-items: center;
}

.last-service-item:hover {
  cursor: pointer;
}

.date {
  background: #d0fafe;
  color: #069aa9;
  width: 50px;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
}

.type {
  color: #069aa9;
  font-size: 12px;
}

.type2 {
  color: white;
  width: 110px;
  border-radius: 25px;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
}

.befall {
  background: #cb3844;
}

.kein-befall {
  background: #0eaa00;
}

.pflege {
  background: #15a8fb;
}

.nicht {
  background: #ff8000;
}

.loading-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rounded__type {
  color: #069aa9;
  background: rgba(237, 239, 245, 0.8);
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 6px;
}

@media only screen and (min-width: 1000px) {
  .customer-monitors {
    background-color: #fff;
    border-radius: 12px;
    padding: 36px 48px;
  }
}
